div.breadcrumbs {
  padding-left: 0;
}
div.breadcrumbs .items .item {
  color: #000000;
}
div.breadcrumbs .items .item a:hover {
  color: #0087D2;
}
div.breadcrumbs .items .item.home {
  display: none;
}

.catalog-category-view .filter-options .am-labels-folding .item {
  padding: 0 20px 0 0;
}
.catalog-category-view .am-labels-folding .am-collapse-icon,
.catalog-category-view .filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .am-collapse-icon {
  left: unset;
  right: 0;
  margin: 0;
}
.catalog-category-view .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ .am-input,
.catalog-category-view .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ .am-input,
.catalog-category-view .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=radio],
.catalog-category-view .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=radio],
.catalog-category-view .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=checkbox],
.catalog-category-view .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=checkbox],
.catalog-category-view .filter-options [class*=am-filter-items-].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type=checkbox] + .amshopby-choice,
.catalog-category-view .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type=checkbox] + .amshopby-choice {
  top: 0;
  left: 0;
}
.catalog-category-view .filter-options .am-category-wrapper .items.-am-multiselect .item.-filter-parent:not(.-is-collapsible),
.catalog-category-view .filter-options .items:not(.items-children) > .item.-is-by-click.-filter-parent ~ .item.-is-expanded {
  margin-left: unset;
}
.catalog-category-view .filter-options .am-labels-folding .item > [class*=am-filter-item-] {
  padding-left: 20px;
}
@media (width < 768px) {
  .catalog-category-view .filter-options .am-labels-folding .item > [class*=am-filter-item-] {
    padding-left: 35px;
  }
  .catalog-category-view .filter-options input[type=checkbox] + .amshopby-choice:before,
  .catalog-category-view .filter-options input[type=checkbox] + .amshopby-choice:after {
    width: 15px;
    height: 15px;
  }
  .catalog-category-view .filter-options input[type=checkbox] + .amshopby-choice {
    top: 0;
  }
}

section.catalog-intro-row {
  position: relative;
  display: flex;
  overflow-x: clip;
  justify-content: center;
}
section.catalog-intro-row .full-row {
  padding-bottom: 100px;
  padding-top: 50px;
}
@media (width <= 768px) {
  section.catalog-intro-row .full-row {
    padding-top: 20px;
    padding-bottom: 50px;
  }
}
section.catalog-intro-row .full-row .categoryName {
  text-transform: none;
}
section.catalog-intro-row .full-row .categoryDescription * {
  margin: 0;
}
section.catalog-intro-row .decoration-container {
  position: absolute;
  bottom: 0;
}
section.catalog-intro-row .decoration-container .banner-decoration .whitepath {
  color: #FFFFFF;
}
section.catalog-intro-row .decoration-container .banner-decoration .bluepath {
  color: #0087D2;
}
section.catalog-intro-row .decoration-container .banner-decoration .redpath {
  color: #EF0000;
}
section.catalog-intro-row .decoration-container .banner-decoration .blackpath {
  fill: var(--background-color);
}

section.catalog-row {
  background: #FFFFFF;
  padding-top: 40px;
}
section.catalog-row .full-row {
  display: grid;
  grid-template-areas: "filters products-wrapper" "filters products-wrapper";
  gap: 40px;
  grid-template-columns: 1fr 3fr;
  /* Initial styles for products-grid */
  /* Apply styles for products-list when .view-list is present */
}
@media (width < 780px) {
  section.catalog-row .full-row {
    grid-template-areas: unset;
    grid-template-columns: 1fr;
    gap: unset;
  }
}
section.catalog-row .full-row #amasty-shopby-product-list {
  background-color: #FFFFFF;
}
@media (width >= 780px) {
  section.catalog-row .full-row #amasty-shopby-product-list {
    grid-area: products-wrapper;
  }
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar {
  grid-area: toolbar;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 0;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .open-filter-model {
  margin: 0;
  margin-right: auto;
  padding: 13px;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .sorter {
  float: none;
  color: #000000;
  padding: 0;
}
@media (width <= 768px) {
  section.catalog-row .full-row #amasty-shopby-product-list .toolbar .sorter {
    display: grid;
    grid-template-columns: 1fr auto;
  }
  section.catalog-row .full-row #amasty-shopby-product-list .toolbar .sorter .sorter-label {
    grid-column: span 2;
    text-align: left;
  }
  section.catalog-row .full-row #amasty-shopby-product-list .toolbar .sorter #sorter {
    margin: 0;
  }
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .sorter * {
  color: inherit;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .sorter .sorter-options {
  border: 1px solid #989898;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .viewchanger {
  display: grid;
  width: 25px;
  aspect-ratio: 1;
  grid-template-areas: "icon";
  align-items: center;
  cursor: pointer;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .viewchanger svg {
  grid-area: icon;
  width: inherit;
  height: inherit;
  transition: ease 0.3s;
  background: #FFFFFF;
  color: #4A4A4A;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .viewchanger svg.gridview {
  opacity: 0;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .viewchanger svg.listview {
  opacity: 1;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .viewchanger input {
  display: none;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .viewchanger:hover svg {
  color: #0087D2;
  scale: 1.1;
  rotate: z 5deg;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar:has(#viewtoggle:checked) svg.gridview {
  opacity: 1;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar:has(#viewtoggle:checked) svg.listview {
  opacity: 0;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar::before, section.catalog-row .full-row #amasty-shopby-product-list .toolbar::after {
  all: unset;
}
@media (width <= 768px) {
  section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages {
    margin-bottom: 20px;
  }
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items {
  display: flex;
  gap: 10px;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item {
  transition: 0.3s;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item a,
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item strong {
  display: grid;
  place-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #D6D6D6;
  margin: 0;
  position: relative;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item a span,
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item strong span {
  font-size: 20px;
  translate: 0 -2px;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item a.action::before,
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item strong.action::before {
  color: #0087D2;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item.current, section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item:hover {
  background-color: #0087D2;
}
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item.current span,
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item.current .action::before, section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item:hover span,
section.catalog-row .full-row #amasty-shopby-product-list .toolbar .pages .items .item:hover .action::before {
  color: #FFFFFF;
}
section.catalog-row .full-row #amasty-shopby-product-list .products {
  display: grid;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product {
  border-top: 1px solid #D6D6D6;
  display: grid;
  gap: 20px;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product:last-child {
  border-bottom: 1px solid #D6D6D6;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .imagewrapper img {
  object-fit: contain;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .content .brand {
  margin: 0;
  font-size: 15px;
  color: #989898;
  font-weight: bold;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .content .title {
  font-size: 22px;
  color: #333333;
  margin: 0;
  margin-bottom: 20px;
  font-weight: bold;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .content .title a {
  all: inherit;
  transition: 0.3s;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .content .title:hover {
  color: #0087D2;
  cursor: pointer;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .content .description :last-child {
  margin-bottom: 0;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .content .short-description {
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .content .short-description * {
  word-break: break-word;
  white-space: break-spaces;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .price-box [data-price-type=basePrice] {
  display: flex;
  flex-direction: row-reverse;
  justify-content: start;
  align-items: end;
  gap: 5px;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .price {
  color: #000000;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 10px;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .productAddToCart {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #EF0000;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  padding: 9px;
  width: 150px;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .productAddToCart::before {
  background: #FFFFFF;
  z-index: -1;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .productAddToCart .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .productAddToCart:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .productAddToCart:hover * {
  color: #EF0000;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .productAddToCart:hover::before {
  scale: 1.05;
  translate: 0 0;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .quote {
  position: relative;
  overflow: hidden;
  transition: ease-out 0.1s;
  isolation: isolate;
  background: #0087D2;
  border: none;
  border-radius: 50px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  color: #FFFFFF;
  height: max-content;
  padding: 9px;
  margin-right: 10px;
  margin-top: 0;
  width: 150px;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .quote::before {
  background: #FFFFFF;
  z-index: -1;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .quote .brands-link {
  color: #FFFFFF;
  display: block;
  font-size: 14px;
  padding: 10px 20px;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .quote:hover {
  scale: 1.05;
  background-color: #FFFFFF;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .quote:hover * {
  color: #0087D2;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .product .sales .buttons .productAddToCartForm .quote:hover::before {
  scale: 1.05;
  translate: 0 0;
}
section.catalog-row .full-row #amasty-shopby-product-list .products .amshopby-overlay-block > .amshopby-loader {
  top: 50%;
  left: var(--center-position);
  transform: none;
  position: sticky;
  translate: -50%;
  margin-top: var(--spinner-margin-top);
  margin-bottom: var(--spinner-margin-bottom);
}
section.catalog-row .full-row:not(:has(#viewtoggle:checked)) .products {
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}
section.catalog-row .full-row:not(:has(#viewtoggle:checked)) .products .product {
  grid-template-columns: 1fr;
  grid-template-rows: 200px 1fr auto;
  border: 1px solid #D6D6D6;
  padding: 20px;
}
section.catalog-row .full-row:not(:has(#viewtoggle:checked)) .products .product img {
  width: 100%;
  height: 100%;
}
section.catalog-row .full-row:not(:has(#viewtoggle:checked)) .products .product .content {
  padding: 0;
  margin-top: auto;
}
section.catalog-row .full-row:not(:has(#viewtoggle:checked)) .products .product .sales {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin-top: auto;
}
section.catalog-row .full-row:has(#viewtoggle:checked) .products {
  grid-template-columns: 1fr;
}
section.catalog-row .full-row:has(#viewtoggle:checked) .products .product {
  grid-template-rows: auto;
  grid-template-columns: auto 1fr auto;
  padding: 30px 0 30px 30px;
  align-items: flex-start;
}
@media (width <= 768px) {
  section.catalog-row .full-row:has(#viewtoggle:checked) .products .product {
    padding-left: 0;
    grid-template-columns: auto 1fr min-content;
  }
}
@media (width <= 480px) {
  section.catalog-row .full-row:has(#viewtoggle:checked) .products .product {
    grid-template-columns: 1fr minmax(0, 3fr);
    gap: 10px;
  }
  section.catalog-row .full-row:has(#viewtoggle:checked) .products .product .imagewrapper {
    grid-row: span 2;
  }
  section.catalog-row .full-row:has(#viewtoggle:checked) .products .product .productAddToCartForm {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  section.catalog-row .full-row:has(#viewtoggle:checked) .products .product .productAddToCartForm button {
    margin: 0;
  }
}
section.catalog-row .full-row:has(#viewtoggle:checked) .products .product .imagewrapper {
  width: 175px;
}
@media (width <= 768px) {
  section.catalog-row .full-row:has(#viewtoggle:checked) .products .product .imagewrapper {
    width: 100%;
    font-size: 10px;
  }
}
section.catalog-row .full-row:has(#viewtoggle:checked) .products .product .imagewrapper img {
  height: 125px;
  width: 100%;
}
@media (width <= 768px) {
  section.catalog-row .full-row:has(#viewtoggle:checked) .products .product .price {
    font-size: 12px;
  }
  section.catalog-row .full-row:has(#viewtoggle:checked) .products .product .action.primary {
    font-size: 10px;
    padding: 5px;
  }
}
section.catalog-row .category-cms {
  background-color: #FFFFFF;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1280px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}
section.catalog-row .category-cms p {
  margin-bottom: 0;
}

.sidebar.sidebar-main.filters {
  grid-area: filters;
  width: 100%;
}
.sidebar.sidebar-main.filters .filter {
  isolation: isolate;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option {
  display: grid;
  transition: 0.3s;
  overflow: hidden;
  border-bottom: 1px solid #D6D6D6;
  grid-template-rows: max-content 1fr;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-title {
  color: #0087D2;
  margin: 0;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-title label {
  padding: 17px 0;
  display: block;
  cursor: pointer;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-title label input {
  display: none;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .amshopby-slider-wrapper .ui-slider-handle {
  background-color: #0087D2 !important;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-content {
  min-height: 0;
  margin: 0;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-content form {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-content form .rangefield {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 5px;
  height: 35px;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-content form .rangefield input {
  background-color: #FFFFFF;
  box-sizing: content-box;
  padding-left: 10px;
  padding-right: 10px;
  transition: background 0.3s;
  min-width: 5ch;
  text-align: center;
  height: 100%;
  border: 1px solid #E2E1E1;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-content form .rangefield .seperator {
  padding-right: 10px;
  padding-left: 5px;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-content form .rangefield select {
  max-width: max-content;
  padding-left: 0;
  transition: 0.3s;
  padding: 0;
  padding-left: 5px;
  padding-right: 20px;
  border: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--><path d="M256 417.9l17-17L465 209l17-17L448 158.1l-17 17-175 175L81 175l-17-17L30.1 192l17 17L239 401l17 17z"/></svg>');
  background-size: 12px;
  background-position: 87%;
  height: 100%;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-content form .rangefield select:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .filter-options-content form ol {
  order: 1;
  flex-basis: 100%;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .am-filter-items-price .range {
  display: flex;
  margin-bottom: 5px;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .am-filter-items-price .range .amshopby-input-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .am-filter-items-price .range .amshopby-input-wrapper .am-filter-price, .sidebar.sidebar-main.filters .filter .filter-options .filter-option .am-filter-items-price .range .amshopby-input-wrapper .amshopby-currency {
  display: none;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .am-filter-items-price .range .amshopby-input-wrapper input[name*=-display] {
  border: 1px solid #dfdedd;
  color: #494949;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.03em;
  text-align: center;
  width: 100%;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option .am-filter-items-price .range .delimiter {
  width: auto;
  margin: 0 5px;
}
.sidebar.sidebar-main.filters .filter .filter-options .filter-option:has(.collapser:checked) {
  grid-template-rows: max-content 0fr;
}
@media (width < 780px) {
  .sidebar.sidebar-main.filters {
    position: fixed;
    right: -100%;
    top: 0;
    width: 90%;
    background: white;
    z-index: 9;
    padding: 20px 30px;
    border-left: 1px solid #0087D2;
    height: 100%;
    overflow-y: auto;
  }
  .sidebar.sidebar-main.filters.open {
    right: 0;
  }
}

.am_shopby_apply_filters .am-items {
  font-weight: bold;
  font-size: 16px;
}
.am_shopby_apply_filters .amshopby-button {
  background-color: #EF0000;
  border-color: #EF0000;
}
.am_shopby_apply_filters .removeTempChoices {
  display: grid;
  background: none;
  border: none;
  color: #000000;
  padding: 0;
}
.am_shopby_apply_filters .removeTempChoices * {
  grid-column: 1;
  grid-row: 1;
  font-size: 18px;
  padding-left: 5px;
  transition: 0.3s opacity;
}
.am_shopby_apply_filters .removeTempChoices .fa-trash-can-xmark {
  opacity: 0;
}
.am_shopby_apply_filters .removeTempChoices .fa-trash-can {
  opacity: 1;
}
.am_shopby_apply_filters .removeTempChoices:hover .fa-trash-can-xmark {
  opacity: 1;
}
.am_shopby_apply_filters .removeTempChoices:hover .fa-trash-can {
  opacity: 0;
}

body.catalog-category-view .amasty-catalog-topnav {
  display: none;
}