div.breadcrumbs{
    padding-left: 0;
    .items{
        .item{
            color: $black;
            a:hover {
                color: $blue;
            }
        }
        .item.home{
            display: none;
        }
    }
}