.products {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;

    .product {
        grid-template-columns: 1fr;
        grid-template-rows: 200px 1fr auto;
        border: 1px solid $greyLight;
        padding: 20px;

        img {
            width: 100%;
            height: 100%;
        }

        .content {
            padding: 0;
            margin-top: auto;
        }

        .sales {
            display: flex;
            flex-direction: column;
            padding: 0;
            margin-top: auto;
        }
    }
}