.catalog-category-view {
    .filter-options .am-labels-folding .item {
        padding: 0 20px 0 0;
    }

    .am-labels-folding .am-collapse-icon,
    .filter-options .amshopby-category-dropdown + form .items.-am-multiselect .item .am-collapse-icon {
        left: unset;
        right: 0;
        margin: 0;
    }

    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ .am-input,
    .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ .am-input,
    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type='radio'],
    .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type='radio'],
    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type='checkbox'],
    .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type='checkbox'],
    .filter-options [class*='am-filter-items-'].am-labels-folding .item:not(.swatch-option-link-layered) .am-collapse-icon ~ input[type='checkbox'] + .amshopby-choice,
    .filter-options .amshopby-category-dropdown + form .item .am-collapse-icon ~ input[type='checkbox'] + .amshopby-choice {
        top: 0;
        left: 0;
    }

    .filter-options .am-category-wrapper .items.-am-multiselect .item.-filter-parent:not(.-is-collapsible),
    .filter-options .items:not(.items-children) > .item.-is-by-click.-filter-parent ~ .item.-is-expanded {
        margin-left: unset;
    }

    .filter-options .am-labels-folding .item > [class*='am-filter-item-'] {
        padding-left: 20px;
    }

    @media (width < 768px){
        .filter-options .am-labels-folding .item > [class*='am-filter-item-'] {
            padding-left: 35px;
        }

        .filter-options input[type='checkbox'] + .amshopby-choice:before,
        .filter-options input[type='checkbox'] + .amshopby-choice:after {
            width: 15px;
            height: 15px;
        }

        .filter-options input[type='checkbox'] + .amshopby-choice {
            top: 0;
        }

    }
}

