.products {
    grid-template-columns: 1fr;

    .product {
        grid-template-rows: auto;
        grid-template-columns: auto 1fr auto;
        padding: 30px 0 30px 30px;
        align-items: flex-start;

        @media(width <=768px) {
            padding-left: 0;
            grid-template-columns: auto 1fr min-content;
        }

        @media(width <=480px) {
            grid-template-columns: 1fr minmax(0, 3fr);
            gap: 10px;

            .imagewrapper {
                grid-row: span 2;
            }

            .productAddToCartForm {
                display: flex;
                gap: 10px;
                margin-top: 10px;

                button {
                    margin: 0;
                }
            }
        }

        .imagewrapper {
            width: 175px;

            @media(width <=768px) {
                width: 100%;
                font-size: 10px;
            }

            img {
                height: 125px;
                width: 100%;
            }
        }

        @media(width <=768px) {
            .price {
                font-size: 12px;
            }

            .action.primary {
                font-size: 10px;
                padding: 5px;
            }
        }
    }
}