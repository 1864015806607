section.catalog-intro-row{
    position: relative;
    display: flex;
    overflow-x: clip;
    justify-content: center;
    .full-row{
        padding-bottom: 100px;
        padding-top: 50px;
        @media(width <= 768px){
            padding-top: 20px;
            padding-bottom: 50px;
        }
        .categoryName{
            text-transform: none;
        }
        .categoryDescription{
            *{
                margin: 0;
            }
        }
    }
    .decoration-container{
        position: absolute;
        bottom: 0;
        .banner-decoration{
            .whitepath{
                color: $white;
            }
            .bluepath{
                color: $blue;
            }
            .redpath{
                color: $red;
            }
            .blackpath{
                fill: var(--background-color);
            }
        }
    }
}