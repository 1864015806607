section.catalog-row {
    background: $white;
    padding-top: 40px;

    .full-row {
        display: grid;
        grid-template-areas:
            "filters products-wrapper"
            "filters products-wrapper"
        ;
        gap: 40px;
        grid-template-columns: 1fr 3fr;

        @media(width < 780px) {
            grid-template-areas: unset;
            grid-template-columns: 1fr;
            gap: unset;
        }

        #amasty-shopby-product-list {
            @media(width >=780px) {
                grid-area: products-wrapper;
            }

            background-color: $white;

            .toolbar {
                grid-area: toolbar;
                display: flex;
                justify-content: end;
                align-items: center;
                gap: 20px;
                margin: 0;
                padding: 0;

                .open-filter-model {
                    margin: 0;
                    margin-right: auto;
                    padding: 13px;
                }

                .sorter {
                    float: none;
                    color: $black;
                    padding: 0;

                    @media(width <= 768px) {
                        display: grid;
                        grid-template-columns: 1fr auto;

                        .sorter-label {
                            grid-column: span 2;
                            text-align: left;
                        }

                        #sorter {
                            margin: 0;
                        }
                    }

                    * {
                        color: inherit;
                    }

                    .sorter-options {
                        border: 1px solid $greyDark;
                    }
                }

                .viewchanger {
                    display: grid;
                    width: 25px;
                    aspect-ratio: 1;
                    grid-template-areas: "icon";
                    align-items: center;
                    cursor: pointer;

                    svg {
                        grid-area: icon;
                        width: inherit;
                        height: inherit;
                        transition: ease .3s;
                        background: $white;
                        color: $greyDarker;

                        &.gridview {
                            opacity: 0;
                        }

                        &.listview {
                            opacity: 1;
                        }
                    }

                    input {
                        display: none;
                    }

                    &:hover svg {
                        color: $blue;
                        scale: 1.1;
                        rotate: z 5deg;
                    }
                }

                &:has(#viewtoggle:checked) svg {
                    &.gridview {
                        opacity: 1;
                    }

                    &.listview {
                        opacity: 0;
                    }
                }

                &::before,
                &::after {
                    all: unset;
                }

                .pages {
                    @media(width <=768px) {
                        margin-bottom: 20px;
                    }

                    .items {
                        display: flex;
                        gap: 10px;

                        .item {
                            transition: .3s;

                            a,
                            strong {
                                display: grid;
                                place-content: center;
                                width: 40px;
                                height: 40px;
                                border: 1px solid $greyLight;
                                margin: 0;
                                position: relative;

                                span {
                                    font-size: 20px;
                                    translate: 0 -2px;
                                }

                                &.action::before {
                                    color: $blue;
                                }
                            }

                            &.current,
                            &:hover {
                                background-color: $blue;

                                span,
                                .action::before {
                                    color: $white;
                                }
                            }
                        }
                    }
                }
            }

            .products {
                display: grid;

                .product {
                    border-top: 1px solid $greyLight;
                    display: grid;
                    gap: 20px;

                    &:last-child {
                        border-bottom: 1px solid $greyLight;
                    }

                    .imagewrapper {
                        img {
                            object-fit: contain;
                        }
                    }

                    .content {
                        .brand {
                            margin: 0;
                            font-size: 15px;
                            color: $greyDark;
                            font-weight: bold;
                        }

                        .title {
                            font-size: 22px;
                            color: $greyDarkest;
                            margin: 0;
                            margin-bottom: 20px;
                            font-weight: bold;

                            a {
                                all: inherit;
                                transition: .3s;
                            }

                            &:hover {
                                color: $blue;
                                cursor: pointer;
                            }
                        }

                        .description {
                            :last-child {
                                margin-bottom: 0;
                            }
                        }

                        .short-description {
                            display: -webkit-box;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;

                            * {
                                word-break: break-word;
                                white-space: break-spaces;
                            }
                        }
                    }

                    .sales {
                        .price-box {
                            [data-price-type="basePrice"] {
                                display: flex;
                                flex-direction: row-reverse;
                                justify-content: start;
                                align-items: end;
                                gap: 5px;
                            }
                        }

                        .price {
                            color: $black;
                            font-size: 22px;
                            font-weight: bold;
                            margin: 0;
                        }

                        .buttons {
                            .productAddToCartForm {
                                display: flex;
                                flex-wrap: wrap;
                                align-items: baseline;
                                gap: 10px;

                                .productAddToCart {
                                    @include button($red, $white);
                                    padding: 9px;
                                    width: 150px;
                                }

                                .quote {
                                    @include button($blue, $white);
                                    height: max-content;
                                    padding: 9px;
                                    margin-right: 10px;
                                    margin-top: 0;
                                    width: 150px;
                                }
                            }
                        }
                    }
                }
                
                .amshopby-overlay-block > .amshopby-loader{
                    top: 50%;
                    left: var(--center-position);
                    transform: none;
                    position: sticky;
                    translate: -50%;
                    margin-top: var(--spinner-margin-top);
                    margin-bottom: var(--spinner-margin-bottom);
                }
            }
        }

        /* Initial styles for products-grid */
        &:not(:has(#viewtoggle:checked)) {
            @import "products-grid";
        }

        /* Apply styles for products-list when .view-list is present */
        &:has(#viewtoggle:checked) {
            @import "products-list";
        }
    }

    .category-cms {
        background-color: $white;
        margin-bottom: 0;
        margin-left: auto;
        margin-right: auto;
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;
        width: 100%;
        box-sizing: border-box;

        p {
            margin-bottom: 0;
        }
    }
}

.sidebar.sidebar-main.filters{
    grid-area: filters;
    width: 100%;
    .filter {
        isolation: isolate;

        .filter-options {
            .filter-option {
                display: grid;
                transition: .3s;
                overflow: hidden;
                border-bottom: 1px solid $greyLight;

                .filter-options-title {
                    color: $blue;
                    margin: 0;

                    label {
                        padding: 17px 0;
                        display: block;
                        cursor: pointer;

                        input {
                            display: none;
                        }
                    }
                }

                .amshopby-slider-wrapper {
                    .ui-slider-handle {
                        background-color: $blue !important;
                    }
                }

                .filter-options-content {
                    min-height: 0;
                    margin: 0;
                    .items{

                        // max-height: 200px;
                        // overflow-x: hidden;
                        // overflow-y: auto;
                        // scrollbar-color: #b0a5a5 #00000005;
                        // scrollbar-width: thin;
                    }

                    form{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        .rangefield{
                            display: flex;
                            align-items: center;
                            margin-bottom: 10px;
                            gap: 5px;
                            height: 35px;
                            input{
                                background-color: $white;
                                box-sizing: content-box;
                                padding-left: 10px;
                                padding-right: 10px;
                                transition: background .3s;
                                min-width: 5ch;
                                text-align: center;
                                height: 100%;
                                border: 1px solid $greyLighter;
                                //&:hover{
                                //    background: rgba(0,0,0,0.1);
                                //}
                            }
                            .seperator{
                                // padding: 0 10px;
                                padding-right: 10px;
                                padding-left: 5px;
                            }
                            select{
                                max-width: max-content;
                                padding-left: 0;
                                transition: .3s;
                                padding: 0;
                                padding-left: 5px;
                                padding-right: 20px;
                                border: none;

                                //margin-left: -3px;
                                background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Pro 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2025 Fonticons, Inc.--><path d="M256 417.9l17-17L465 209l17-17L448 158.1l-17 17-175 175L81 175l-17-17L30.1 192l17 17L239 401l17 17z"/></svg>');
                                background-size: 12px;
                                background-position: 87%;
                                height: 100%;
                                // margin-right: 10px;
                                &:hover{
                                    background-color: rgba(0,0,0,0.1);
                                }
                            }
                            input,select{
                            }
                        }
                        ol{
                            order: 1;
                            flex-basis: 100%;
                        }

                    }
                }

                .am-filter-items-price .range{
                    display: flex;
                    margin-bottom: 5px;
                    .amshopby-input-wrapper{
                        display: flex;
                        flex-direction: row-reverse;
                        align-items: center;
                        .am-filter-price, .amshopby-currency{
                            display: none;
                        }
                        input[name*=-display]{
                            border: 1px solid #dfdedd;
                            color: #494949;
                            font-size: 14px;
                            height: 40px;
                            letter-spacing: .03em;
                            text-align: center;
                            width: 100%;
                        }
                    }
                    .delimiter{
                        width: auto;
                        margin: 0 5px;
                    }
                }

                grid-template-rows: max-content 1fr; //open
                &:has(.collapser:checked) {
                    grid-template-rows: max-content 0fr; //closed
                }
            }
        }
    }
    @media(width < 780px){
        position: fixed;
        right: -100%;
        top: 0;
        width: 90%;
        background: white;
        z-index: 9;
        padding: 20px 30px;
        border-left: 1px solid $blue;
        height: 100%;
        overflow-y: auto;
        &.open{
            right: 0;
        }
    }
}

.am_shopby_apply_filters{
    .am-items{
        font-weight: bold;
        font-size: 16px;
    }
    .amshopby-button{
        background-color: $red;
        border-color: $red;
    }
    .removeTempChoices{
        display: grid;
        background: none;
        border: none;
        color: $black;
        padding: 0;
        *{
            grid-column: 1;
            grid-row: 1;
            font-size: 18px;
            padding-left: 5px;
            transition: .3s opacity;
        }
        .fa-trash-can-xmark{
            opacity: 0;
        }
        .fa-trash-can{
            opacity: 1;
        }
        &:hover{
            .fa-trash-can-xmark{
                opacity: 1;
            }
            .fa-trash-can{
                opacity: 0;
            }
        }
    }
}