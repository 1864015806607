@import "../base/variables";
@import "../base/mixins";

@import "../component/breadcrumbs";
@import "../component/sidebar";
@import "../component/category/intro";
@import "../component/category/products";

body.catalog-category-view{
    .amasty-catalog-topnav{
        display: none;
    }
}